import React from 'react';
import axios from 'axios';
import { Link } from 'gatsby';

export default class TrainingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      data: null,
    }
  }

  componentDidMount() {
    try {
      const WOW = require('wowjs');
      const wow = new WOW.WOW();
      wow.init();
    } catch (e) {
      console.error(e);
    }

    axios
      .get(`${process.env.API_URL}/umbraco/api/training/getdetail`)
      .then(result => {
        this.setState({
          data: result.data,
        })
      })
      .catch(error => { })
  }

  render() {
    const { data } = this.state
    let shortTrainingPhotoUrl = '';
    let longTrainingPhotoUrl = '';

    if (data) {
      shortTrainingPhotoUrl = `${process.env.API_URL}${data.ShortTrainingPhoto}`;
      longTrainingPhotoUrl = `${process.env.API_URL}${data.LongTrainingPhoto}`;
    }

    return (
      <div>
        <section className="section-top">
          <div className="container">
            <div className="col-md-6 col-sm-6 col-xs-12">
              <div className="section-top-title">
                <h2>Formações</h2>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 col-xs-12" />
          </div>
        </section>
        <section className="our_activites">
          <div className="container">
            <div className="row text-center">
              <div className="col-md-4 col-md-offset-2 col-sm-12">
                <div className="single_activites card">
                  <img src={shortTrainingPhotoUrl} />
                  <h4 className="text">Formações Curtas</h4>
                  <Link to={`/training-list?type=short`}>
                    <span className="btn-contact-bg solid">Ver Mais</span>
                  </Link>
                </div>
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="single_activites card">
                  <img src={longTrainingPhotoUrl} />
                  <h4 className="text">Formações Longas</h4>
                  <Link to={`/training-list?type=long`}>
                    <span className="btn-contact-bg solid">Ver Mais</span>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    )
  }
}